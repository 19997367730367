@import '~sema-ui-components/dist/components/styles/variables';

.tabContent {
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid $gray-mid;
  font-size: 16px;
  min-height: 300px;
  color: gray(dark);
  letter-spacing: 0;
  box-sizing: border-box;
  padding-left: 40px;
}

